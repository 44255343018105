<template>
    <div class="result-confirmation">
        <div class="loading" v-if="loading"><div class="loader"></div></div>
        <div v-else>
            <p class="title">{{ $t('result_confirmation') }}</p>
            <p class="desc">{{ text }}</p>
            
            <div class="cards-container" v-if="place">
                <div
                    class="card"
                    v-for="(card, ind) in choices"
                    :key="ind"
                >
                    <img
                        :src="require(`@/assets/img/results/${card.id}-${language}.png`)"
                        alt="card"
                    >

                    <p class="name">{{ $t(card.title) }}</p>
                    
                    <ul>
                        <li
                            v-for="(item, index) in card.traits"
                            :key="index"
                        >
                            {{ $t(item) }}
                        </li>
                    </ul>

                    <button @click="select(card)">{{ $t('select') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
    data () {
        return {
            place: '',
            results: [
                {
                    id: 1,
                    title: "Investigative",
                    traits: ['rational', 'objective', 'logical', 'analytical', 'good at problem-solving and strategic thinking', 'intellectual', 'good at reasoning and thinking', 'smart', 'rigorous', 'serious', 'distant from others']
                },

                {
                    id: 2,
                    title: "Introspective",
                    traits: ['perceptual', 'highly intuitive', 'thoughtful', 'sensitive', 'contemplative', 'reserved', 'imaginative', 'enjoys exploring the inner self', 'solitude', 'has a strong self-awareness']
                },
                
                {
                    id: 3,
                    title: "Creative",
                    traits: ['rich in emotions', 'creative', 'humorous', 'confident', 'focused', 'artistic', 'romantic', 'aesthetic', 'sees the world differently', 'sentimental']
                },

                {
                    id: 4,
                    title: "Compassionate",
                    traits: ['passionate about helping others', 'kind', 'empathetic', 'warm', 'expressive', 'considerate', 'cares for others', 'friendly', 'has good interpersonal skills', 'indecisive']
                },

                {
                    id: 5,
                    title: "Sensory",
                    traits: ['straightforward', 'optimistic', 'easy-going', 'energetic', 'generous', 'adventurous', 'free', 'hedonistic', 'lives in the moment', 'indulges in comfort']
                },

                {
                    id: 6,
                    title: "Operative",
                    traits: ['pragmatic', 'realistic', 'hardworking', 'action-oriented', 'practical', 'persevering', 'rules-oriented', 'responsible', 'takes things step-by-step', 'assertive']
                },

                {
                    id: 7,
                    title: "Conventional",
                    traits: ['conventional', 'detail-oriented', 'cautious', 'patient', 'persistent', 'organized', 'self-disciplined', 'good at planning', 'introvert', 'conservative']
                },

                {
                    id: 8,
                    title: "Enterprising",
                    traits: ['proactive', 'flexible', 'ambitious', 'visionary', 'brave to break through', 'good at strategies', 'seizes opportunities', 'good at integrating and planning', 'has a broad network of contacts', 'utilitarian']
                },

                {
                    id: 9,
                    title: "Dominant",
                    traits: ['leadership', 'good at organizing', 'dominant', 'ambitious', 'confident', 'aggressive', 'decisive', 'charismatic', 'perfectionist', 'self-centered']
                },

                {
                    id: 10,
                    title: "Sociable",
                    traits: ['outgoing', 'positive', 'optimistic', 'hospitable', 'talkative', 'good at socializing', 'observant', 'resilient', 'adaptable', 'versatile', 'enjoys debating']
                },

                {
                    id: 11,
                    title: "Helpful",
                    traits: ['kind', 'good-tempered', 'enthusiastic', 'patient', 'behind-the-scenes', 'reliable', 'easy-going', 'amicable', 'self-sacrifice and dedication', 'has no principles']
                },

                {
                    id: 12,
                    title: "Natural",
                    traits: ['innocent', 'natural', 'kind', 'contented', 'has simple desires', 'generous', 'sincere', 'honest', 'compassionate', 'overly idealistic']
                }
            ]
        }
    },

    computed: {
        ...mapFields(['score', 'selectedCards', 'submittedAnswer', 'loading', 'language']),

        choices () {
            // get ids of same count
            let arr = this.score.filter(x => x.count === this.score[this.place-1].count && !x.isSelected)

            return arr.map(i => this.results[i.id-1])
        },

        text () {
            if (this.place === 1) return this.$t('select_top_1')
            else if (this.place === 2) return this.$t('select_top_2')
            else if (this.place === 3) return this.$t('select_top_3')
            else return ''
        }
    },

    created () {
        if (this.selectedCards.length < 1) this.$router.push('card-assessment')
        this.checkIfHasTied()
    },

    methods: {
        checkIfHasTied () {
            for (let index = 0; index < 3; index++) {
                const item = this.score[index]

                // if has tied result
                if (item.count === this.score[index+1].count && !item.isSelected) {
                    this.place = index+1
                    console.log(`TOP ${index+1} Tied Result with id:${item.id} and count of :${item.count}`)
                    return   
                }
            }

            this.save()
        },

        select (card) {
            //index of selected card 
            let ind = this.score.findIndex(x=>x.id === card.id)

            // create temporary storage for picked card
            let obj = this.score[ind]
            // add indicator to picked card so that this will not appear on tied result again
            obj.isSelected = true

            // remove the selected card on score array
            this.score.splice(ind, 1)

            // add card on top pick
            this.score.splice(this.place-1, 0, obj)

            // check for tied result again
            this.checkIfHasTied()
        },

        async save () {
            this.place = ''
            let total = 0
            this.score.map(x => total += parseInt(x.count))
            
            let form = new FormData()
			let counter = 0
            this.submittedAnswer.forEach((item, index) => {
                form.append(`cards[${index}]`, item.id)

                item.answers.forEach((ans) => {
                    form.append(`reasons[${counter}][card_id]`, ans.card_id)
                    form.append(`reasons[${counter}][card_reason_id]`, ans.id)
                    form.append(`reasons[${counter}][assessment_type_id]`, ans.assessment_type_id)
					counter++
                })
            })

            this.score.forEach((item, index) => {
                form.append(`assessment_result[${item.id}][type_count]`, item.count)
                form.append(`assessment_result[${item.id}][order]`, index+1)
                form.append(`assessment_result[${item.id}][percent]`, Math.round((item.count/total)*100))
            })

            await this.$store.dispatch('submitExam', form)
            // console.log('save')
        }
    }
}
</script>

<style lang="scss" scoped>
.result-confirmation {
    max-width: 1200px;
    margin: auto;
    padding: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .loading {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        height: 100%;
        width: 100%; 
        background-color: #000;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        z-index: 9999;;
        opacity: 0.8;

        

        .loader {
            border: 16px solid #f3f3f3;
            border-radius: 50%;
            border-top: 16px solid #00AEEF;
            width: 120px;
            height: 120px;
            -webkit-animation: spin 2s linear infinite; /* Safari */
            animation: spin 2s linear infinite;

            @-webkit-keyframes spin {
                0% { -webkit-transform: rotate(0deg); }
                100% { -webkit-transform: rotate(360deg); }
            }

            @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
            }
        }
    }

    .title {
        font-family: 'Crimson Pro', sans-serif;
        font-weight: 700;
        font-size: 26px;
        padding-bottom: 24px;
        margin-bottom: 19px;
        border-bottom: 1px solid #B8B8B8;
        width: 100%;
        text-align: center;
    }

    .desc {
        text-align: center;
        font-family: 'Crimson Pro', sans-serif;
        font-weight: 700;
        font-size: 24px;
        margin-bottom: 23px;
    }

    .cards-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .card {
            border: 1px solid #B8B8B8;
            border-radius: 3px;
            padding: 20px;
            text-align: center;
            margin-right: 21px;
            margin-bottom: 21px;
            width: 245px;

            img {
                width: 184px;
                height: 220px;
                margin-bottom: 15px;
            }

            .name {
                font-weight: 600;
                font-size: 18px;
                margin-bottom: 14px;
            }

            ul {
                text-align: left;
                padding-left: 15px;
                margin-bottom: 24px;
                list-style: inside;

                li {
                    font-size: 14px;
                    margin-bottom: 10px;
                    text-transform: capitalize;
                }
            }

            button {
                border: 1px solid #00AEEF;
                color: #00AEEF;
                background-color: #ffffff;
                width: 80px;
            }
        }
    }

    .footer {
        display: flex;
        align-items: center;
        position: fixed;
        padding: 20px 50px;
        width: 100%;
        box-shadow: 0px -6px 6px #00000047;
        background-color: #ffffff;

        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;

        .buttons {
            margin-left: auto;

            button {
                width: 125px;
                font-weight: 600;
    
                &.return {
                    background-color: #ffffff;
                    border: 1px solid #FF6868;
                    color: #FF6868;
                    margin-right: 16px;
                }
    
                &.done {
                    background-color: #00AEEF;
                    border: 1px solid #00AEEF;
                    color: #ffffff;
                }
    
                &:disabled {
                    cursor: not-allowed;
                    background-color: #B8B8B8;
                    border: 1px solid #B8B8B8;
                    color: #ffffff;
                }
            }
        }

    }
}
</style>